var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-content",class:{
    'do-flex-col': _vm.isImgPropEmpty(),
    'section-content__gradient': _vm.withLinearGradientBackground,
  },style:(`background: ${_vm.customGradientBackground}`)},[(!_vm.title.isEmpty)?_c('div',{staticClass:"section-content__title",class:_vm.colorTitle,domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),_c('div',{class:{ 'do-flex section-content__list-wrapper': !_vm.isImgPropEmpty() }},[_c('div',{class:{
        'do-flex section-content__item-card': _vm.isImgPropEmpty(),
        'do-flex-col section-content__item-card-img': !_vm.isImgPropEmpty(),
        'section-content__item-card-title-not-used': _vm.title == '',
      }},_vm._l((_vm.contentsList),function(content,index){return _c('div',{key:index,class:{
          'section-content__item': _vm.isImgPropEmpty(),
          'section-content__item-img': !_vm.isImgPropEmpty(),
          'section-content__item-center': content.isFlexDirectionColumn,
        }},[_c('contents-list',{attrs:{"content":content,"has-click-able":content.hasClickEvent}},[_vm._t("default")],2)],1)}),0),_vm._v(" "),(!_vm.isImgPropEmpty())?_c('ImageLazy',{staticClass:"ill-img",attrs:{"src":_vm.illImg,"alt":"illustration.png"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }