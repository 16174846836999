var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'contents-list do-flex':
      !_vm.isBgColorPropEmpty() && !_vm.content.isFlexDirectionColumn,
    'contents-list do-flex not-bg-color':
      _vm.isBgColorPropEmpty() && !_vm.content.isFlexDirectionColumn,
    'contents-list do-flex-col justify-content-center':
      !_vm.isBgColorPropEmpty() && _vm.content.isFlexDirectionColumn,
  }},[_c('div',[_c('div',{class:{
        'contents-list__bg-color': _vm.isBgColorPropEmpty(),
        'contents-list__bg-image': !_vm.isBgColorPropEmpty(),
        'contents-list__bg-center': _vm.content.isFlexDirectionColumn,
      },style:(`background-color: ${_vm.content.icoBgColor}`)},[(_vm.content.img)?_c('img',{attrs:{"src":_vm.getImageIll,"alt":_vm.content.img}}):_c('div',{style:(`color: ${_vm.content.numberColor}`)},[_vm._v("\n        "+_vm._s(_vm.content.number)+"\n      ")])])]),_vm._v(" "),_c('div',{staticClass:"do-flex-col justify-content-center"},[_c('div',{class:_vm.content.isFlexDirectionColumn
          ? 'contents-list__title-center'
          : 'contents-list__title',domProps:{"innerHTML":_vm._s(_vm.content.title)}}),_vm._v(" "),(!_vm.hasClickAble)?_c('div',{staticClass:"contents-list__desc",domProps:{"innerHTML":_vm._s(_vm.content.desc)}}):_c('div',[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }