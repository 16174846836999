<template>
  <div
    class="section-content"
    :class="{
      'do-flex-col': isImgPropEmpty(),
      'section-content__gradient': withLinearGradientBackground,
    }"
    :style="`background: ${customGradientBackground}`"
  >
    <div
      v-if="!title.isEmpty"
      class="section-content__title"
      :class="colorTitle"
      v-html="title"
    />
    <div
      :class="{ 'do-flex section-content__list-wrapper': !isImgPropEmpty() }"
    >
      <div
        :class="{
          'do-flex section-content__item-card': isImgPropEmpty(),
          'do-flex-col section-content__item-card-img': !isImgPropEmpty(),
          'section-content__item-card-title-not-used': title == '',
        }"
      >
        <div
          v-for="(content, index) in contentsList"
          :key="index"
          :class="{
            'section-content__item': isImgPropEmpty(),
            'section-content__item-img': !isImgPropEmpty(),
            'section-content__item-center': content.isFlexDirectionColumn,
          }"
        >
          <contents-list
            :content="content"
            :has-click-able="content.hasClickEvent"
          >
            <slot />
          </contents-list>
        </div>
      </div>
      <ImageLazy
        v-if="!isImgPropEmpty()"
        :src="illImg"
        class="ill-img"
        alt="illustration.png"
      />
    </div>
  </div>
</template>

<script>
import ContentsList from "./ContentsList";
import ImageLazy from "../ImageLazy/index.vue";

export default {
  name: "ProfitAndRegistrationContent",
  components: { ContentsList, ImageLazy },
  props: {
    contentsList: {},
    title: {
      type: String,
      default: "",
    },
    colorTitle: {
      type: String,
      default: "",
    },
    illImg: {
      type: String,
      default: "",
    },
    withLinearGradientBackground: {
      type: Boolean,
      default: false,
    },
    customGradientBackground: {
      type: String,
      default: "",
    },
  },
  methods: {
    /** @return {boolean} */
    isImgPropEmpty() {
      return this.illImg === "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/style";
@import "src/assets/css/flexbox";
@import "src/assets/css/calculate";

@mixin isWrapContent {
  flex-wrap: wrap-reverse;
  justify-content: center;
}

@mixin CustomFontSize($font-size: 34px, $line-height: 51px) {
  font-size: $font-size;
  line-height: $line-height;
}

.section-content {
  padding: 20px 0;
  width: 100%;

  &__list-wrapper {
    margin: 80px 8.42% 40px;
    justify-content: center;
    @include for-size(mobile) {
      margin: 40px 24px;
    }
    @media (max-width: 719px) {
      flex-wrap: wrap-reverse;
    }
  }

  &__title {
    width: 100%;
    color: $color-base-text;
    padding: 0 24px;
    font-weight: bold;
    text-align: center;
    @include cal-size(font-size, 18, 26);
    @include cal-size(line-height, 28, 36);

    ::v-deep span {
      color: $color-base;
    }

    @include for-size(mobile) {
      margin-bottom: 0;
    }

    &.red {
      color: $color-base;
    }
  }

  .ill-img {
    min-width: 377px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 990px) {
      width: 100%;
    }

    @include for-size(mobile) {
      min-width: 100%;
    }

    > ::v-deep img {
      width: 377px;
      height: auto;
      margin: 0 auto;
  
      @include for-size(mobile) {
        width: 312px;
      }
    }
  }

  &__gradient {
    background: linear-gradient(to top, #ffffff 50%, #fff7f7 100%, #ffffff);
    @include for-size(mobile) {
      background: linear-gradient(0deg, #fff 0%, #fff7f7 100%, #fff7f7 50%);
    }
  }

  &__item-card-img {
    flex-wrap: nowrap;
    margin-right: 80px;
    padding-top: 0;
    @include for-size(mobile) {
      padding-top: 40px;
    }
    @media (max-width: 719px) {
      margin-right: 0;
    }
  }

  &__item-card {
    margin: 60px 6.7%;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item-card-title-not-used {
    margin: 0px 6.7%;
    flex-wrap: wrap;
  }

  &__item-img {
    margin-bottom: 24px;
  }

  &__item {
    margin-bottom: 0;
    flex-basis: 50%;
    padding: 30px 20px;
    @include for-size(mobile) {
      padding: 12px 0;
    }
    @media (max-width: 719px) {
      flex-basis: 100%;
    }
  }

  &__item-center {
    text-align: center;
    flex-basis: 30%;

    @include for-size(mobile) {
      padding: 12px 0;
    }
    @media (max-width: 719px) {
      flex-basis: 100%;
    }
  }
}
</style>
