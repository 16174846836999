<template>
  <div
    :class="{
      'contents-list do-flex':
        !isBgColorPropEmpty() && !content.isFlexDirectionColumn,
      'contents-list do-flex not-bg-color':
        isBgColorPropEmpty() && !content.isFlexDirectionColumn,
      'contents-list do-flex-col justify-content-center':
        !isBgColorPropEmpty() && content.isFlexDirectionColumn,
    }"
  >
    <div>
      <div
        :class="{
          'contents-list__bg-color': isBgColorPropEmpty(),
          'contents-list__bg-image': !isBgColorPropEmpty(),
          'contents-list__bg-center': content.isFlexDirectionColumn,
        }"
        :style="`background-color: ${content.icoBgColor}`"
      >
        <img
          v-if="content.img"
          :src="getImageIll"
          :alt="content.img"
        >
        <div
          v-else
          :style="`color: ${content.numberColor}`"
        >
          {{ content.number }}
        </div>
      </div>
    </div>
    <div class="do-flex-col justify-content-center">
      <div
        :class="
          content.isFlexDirectionColumn
            ? 'contents-list__title-center'
            : 'contents-list__title'
        "
        v-html="content.title"
      />
      <div
        v-if="!hasClickAble"
        class="contents-list__desc"
        v-html="content.desc"
      />
      <div v-else>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContentsList',
  props: {
    content: {
      type: Object,
      default() {
        return {
          title: '',
          desc: '',
          img: '',
          icoBgColor: '',
          number: '',
          numberColor: '',
          bgColor: 'red',
          isFlexDirectionColumn: false,
        };
      },
    },
    hasClickAble: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  computed: {
    getImageIll() {
      return `/images/${this.content.img}`;
    },
  },
  methods: {
    isBgColorPropEmpty() {
      return this.content.bgColor == '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/style";
@import "../../assets/css/flexbox";

.not-bg-color {
  align-items: center;
}

.contents-list {
  .justify-content-center {
    justify-content: center;
  }

  &__bg-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fee8e8;
    height: 64px;
    width: 64px;
    border-radius: 50%;
    margin-right: 24px;

    @include for-size(mobile) {
      height: 44px;
      width: 44px;
    }

    img {
      width: 32px;
      height: 32px;

      @include for-size(mobile) {
        height: 20px;
        width: 20px;
      }
    }

    div {
      color: $color-base;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;

      @include for-size(mobile) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  &__bg-color {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    height: 80px;
    width: 80px;
    margin-right: 24px;

    ::v-deep img {
      width: 80px;
      height: 80px;
    }
  }

  &__bg-center {
    margin: 0 auto;

    ::v-deep img {
      width: 32px;
      height: 32px;
    }

    @include for-size(mobile) {
      height: 64px;
      width: 64px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 8px;
  }

  &__title-center {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    padding: 24px 0 8px 0;
  }

  &__desc {
    font-size: 14px;
    line-height: 24.5px;

    ::v-deep a {
      color: $color-base;
      text-decoration: underline;
    }
  }
}
</style>
